import React from 'react';
import { makeStyles } from '@material-ui/core';
import { StaticImage } from 'gatsby-plugin-image';

import Page from '../../../components/Page';
import Section from '../../../components/Section';
import Typography from '../../../components/Typography';
import Card from '../../../components/Card';
import SafeLink from '../../../components/SafeLink';

const useStyles = makeStyles(theme => ({
    bullet: {
      '&:before': {
        content: '"\u2022"'
      }
    }
  }));

const CognitiveReappraisal = () => {

    const classes = useStyles();

    return (
        <Page title='Cognitive Reappraisal: What Is Thought Reframing?'>
            <Section theme='primary'>
                <Typography type='hero' preset={1}>Cognitive Reappraisal: What Is Thought Reframing?</Typography>
                <Typography type='paragraph' preset={1}>In here: Emotional health, Daily activity</Typography>
                <Typography type='paragraph' preset={1} disableMargin>Joshua McInnes <span className={ classes.bullet } /> 4 min read time</Typography>
            </Section>
            <Section>
                <StaticImage alt='Palm leaves' src='../../../images/article-hero-e855e3ea-c2d8-44aa-83cb-6c60f041e23c.png' style={{width: '100%'}} />
            </Section>
            <Section>
                <Typography type='paragraph' preset={1}>What is cognitive reappraisal and how can it help manage our feelings?</Typography>
                <Card theme='mint'>
                    <Typography type='heading' preset={2}>Summary</Typography>
                    <Typography type='paragraph' preset={1}>There is a direct relationship between our thoughts and our emotions.</Typography>
                    <ul>
                    <li>
                            <Typography type='paragraph' preset={1}>Our thoughts precede and influence how we feel about things.</Typography>
                        </li>
                        <li>
                            <Typography type='paragraph' preset={1}>Cognitive Reframing is a technique that can help us with unwanted feelings by adjusting our instinctive thought patterns that inform those feelings.</Typography>
                        </li>
                        <li>
                            <Typography type='paragraph' preset={1}>The technique involves:</Typography>
                            <ul>
                            <li>
                                    <Typography type='paragraph' preset={1}>identifying the negative thoughts</Typography>
                                </li>
                                <li>
                                    <Typography type='paragraph' preset={1}>evaluating the reality of the situation</Typography>
                                </li>
                                <li>
                                    <Typography type='paragraph' preset={1}>and encouraging more positive interpretations.</Typography>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </Card>
                <Typography type='paragraph' preset={1}>Feelings are sometimes considered abstract, unknowable concepts. This can lead to us accepting our feelings as unchangeable. Sometimes loneliness creeps in and we let it sit there. Sometimes frustration washes over us and we relish the anger.</Typography>
                <Typography type='paragraph' preset={1}>There is a technique used in cognitive behavioural therapy, however, that encourages our ability to affect our feelings by adjusting the thoughts that precede them. This can help us process and even limit or reshape unwanted or overwhelming feelings.</Typography>

                <Typography type='heading' preset={2}>What Is Cognitive Reframing?</Typography>
                <Typography type='paragraph' preset={1}>Cognitive reframing - also known as 'cognitive reappraisal' - is a therapy strategy that helps with emotional regulation. It takes advantage of the fact that our thoughts occur before our feelings, and that there is a causal link between the negativity of one and the other.</Typography>
                <Typography type='paragraph' preset={1}>"When we have intense, negative emotions - such as depression and anxiety - our thoughts are often overly negative. These negative thoughts can intensify and prolong feelings of depression and anxiety," says <SafeLink to='https://www.zencare.co/provider/psychiatrist/bradley-miller'>Dr. Bradley Miller</SafeLink>, a psychiatrist and neuroscientist in New York City who specialises in therapy and management of anxiety and depression.</Typography>
                <Typography type='paragraph' preset={1}>Cognitive reframing employs strategies that reshape our instinctive interpretation of events - which are often negative - and encourages healthier and more realistic thought patterns. This keeps us grounded and results in fewer overwhelming emotional responses to stimulus.</Typography>
                <Typography type='paragraph' preset={1}>"Individuals who report greater amounts of well-being and daily positive emotion report using [reframing] more frequently than people who report daily negative emotion," <SafeLink to='https://scholar.google.com/citations?user=igOYGOYAAAAJ&hl=en'>Kateri McRae</SafeLink>, a University of Denver psychologist who works with the study of emotions says.</Typography>
                <Typography type='paragraph' preset={1}>However, despite its widely accepted effectiveness in behavioural therapy, studies have shown that cognitive reframing isn't always a technique people naturally know <SafeLink to='https://pubmed.ncbi.nlm.nih.gov/24999912/'>how to employ intuitively</SafeLink>.</Typography>

                <Typography type='heading' preset={2}>How Does Cognitive Reframing Work?</Typography>
                <Typography type='paragraph' preset={1}>The <SafeLink to='https://cogbtherapy.com/cbt-blog/2014/5/4/hhy104os08dekc537dlw7nvopzyi44'>first step</SafeLink> requires identifying the negative thought or harmful interpretation of events that's leading to overwhelming negative feelings.</Typography>
                <Typography type='paragraph' preset={1}>Once we've named the negative thought patterns, the next step is to calmly and clinically re-evaluate the reality.</Typography>
                <Typography type='paragraph' preset={1}>Then instead of embracing the negative thought(s), we actively practice more positive, self-compassionate, and flexible interpretations. Even if we don't believe it initially, this will develop more naturally positive thought patterns and in time, result in more grounded and manageable feelings.</Typography>
                <ol>
                    <li>
                        <Typography type='paragraph' preset={1}>Identify negative thoughts</Typography>
                        <Typography type='paragraph' preset={1}>When you experience a negative feeling point it out to yourself. Try to give it a name. What is the feeling exactly? Maybe you feel anxious giving a presentation and start to focus on all the ways it may go wrong. Stop the thought mid-flow by verbally or internally saying "stop", if you need to.</Typography>
                    </li>
                    <li>
                        <Typography type='paragraph' preset={1}>Re-evaluate your circumstances</Typography>
                        <Typography type='paragraph' preset={1}>When you identify negative thoughts note all the evidence that supports a negative interpretation and all the evidence that doesn't</Typography>
                        <ul>
                            <li>
                                <Typography type='paragraph' preset={1}>even if you don't believe the positive interpretations initially.</Typography>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Typography type='paragraph' preset={1}>Practice positive interpretations</Typography>
                        <Typography type='paragraph' preset={1}>The last step can be as simple as speaking kindly to yourself or reminding yourself things are not as bad as they first seemed. If you are focussed on all the ways a presentation could go wrong, consciously remind yourself of the ways it could go right. Try to be specific even if you don't believe the possible positive outcomes are likely.</Typography>
                    </li>
                </ol>
                <Typography type='paragraph' preset={1}>Over time this will become a more natural process and we will develop more positive thought patterns.</Typography>
                <Typography type='paragraph' preset={1}>In the cases where cognitive reframing can help manage our feelings, we are not simply 'using the power of positive thinking'. Our instinctive thought patterns and feelings in these instances are not accurate interpretation of events.</Typography>
                <Typography type='paragraph' preset={1}>As our reframed thoughts develop organic, healthier thought patterns our interpretation of events becomes not just more positive but more realistic. The reframed thoughts are not a rejection of reality, but in fact more grounded than our instinctive, overwhelmingly negative thoughts may have been.</Typography>
                <Typography type='paragraph' preset={1}>Things are rarely as bad as they seem when we fall into negative thought patterns.</Typography>

                <Typography type='heading' preset={2}>Things To Remember</Typography>
                <Typography type='paragraph' preset={1}>Cognitive reframing isn't just a short-term fix but has long-term goals too.</Typography>
                <ul>
                <li>
                        <Typography type='paragraph' preset={1}>In the short term</Typography>
                        <Typography type='paragraph' preset={1}>Regular cognitive reframing when faced with negative events can help adjust our mood and temper overwhelming emotions in the immediate moment. This isn't about bottling up feelings but instead encouraging healthier thoughts and managing our negative feelings.</Typography>
                    </li>
                    <li>
                        <Typography type='paragraph' preset={1}>In the long term</Typography>
                        <Typography type='paragraph' preset={1}>Focusing daily on balanced and positive thoughts is a great way to shift a negative mindset to a healthier one. Dr. Miller suggests repeated practice is the best way to shift our overall 'sense of mood'. "Cognitive reframing can lead to a long-term shift in your thinking, and more balanced and positive thinking becomes automatic," he says. People who claim to use cognitive reframing techniques daily generally report having more happy daily emotions on average.</Typography>
                    </li>
                </ul>
                <Typography type='paragraph' preset={1}>Be mindful that regular, overwhelming negative feelings may be the result of ingrained negative thought patterns. Cognitive reframing has proven to be an effective tool in adjusting these patterns of thought but is only one method.</Typography>
                <Typography type='paragraph' preset={1}>Whether you decide to consciously practice cognitive reframing techniques and regardless of where you are at in your mental health journey, be patient with yourself and those around you. When possible, practice compassion with yourself.</Typography>
                <Typography type='paragraph' preset={1}>This concludes the first part of our series on Cognitive Reframing. If you'd like to know more, you can read about <SafeLink to='https://individual.innowell.org/learn/Article/982110a2-cace-496b-b0fe-d4d1513a5ccb'>Cognitive Reappraisal: Strategies for Thought Reframing</SafeLink>.</Typography>

                <Typography type='paragraph' preset={1}><i>TRIVIA: <SafeLink to='https://www.6seconds.org/2004/04/17/white-paper-emotional-contagion/'>Feelings really can be contagious</SafeLink>. As social creatures, we trade information about how we're feeling verbally and non-verbally - and these cues affect each other's moods.</i></Typography>
            </Section>
        </Page>
    )
};

export default CognitiveReappraisal;